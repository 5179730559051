
export const isBrowser = typeof window !== "undefined"

export const isEmpty = value => {
  if (typeof value === 'string'){
    return !value.trim().length;
  } else {
    return !value
  }
}
