/**
 * Main wrapper for gatsby-browser.js and gatsby-ssr.js
 */
import React from 'react';
import { 
  AppWrapper
} from './src/contexts'

export default function RootWrapper({ element }) {
  return (
    <AppWrapper>
        {element}
    </AppWrapper>
  )
}
